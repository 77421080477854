/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container, Nav , NavbarBrand,
  Navbar,
  NavItem,
  NavLink } from "reactstrap";
import MessengerCustomerChat from 'react-messenger-customer-chat';

function PageFooter() {
  return (

    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <div className="credit">
            <span>
              © {new Date().getFullYear()}, Nexus Christian Fellowship.   All rights reserved
              <br></br>
            </span>
          </div>
        </Row>  
        <Row>
          <div className="credit">
              <a class="btnsc btn-icon btn-facebook" href="https://www.facebook.com/NexusForJesus/"><i class="fa fa-facebook"></i></a>
              <a class="btnsc btn-icon btn-youtube" href="https://www.youtube.com/user/nexusfellowship"><i class="fa fa-youtube"></i></a>
              <a class="btnsc btn-icon btn-twitter" href="https://twitter.com/nexusforjesus"><i class="fa fa-twitter"></i></a>
              <a class="btnsc btn-icon btn-instagram" href="https://www.instagram.com/nexusforjesus"><i class="fa fa-instagram"></i></a>
          </div>
        </Row> 
        <MessengerCustomerChat
        pageId="124784842789"
        appId="1762385367247330"
      />


      </Container>
    </footer>
  );
}

export default PageFooter;

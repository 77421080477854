/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from 'react';

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SectionWelcome from "views/sections/SectionWelcome.js";
import SectionExpectation from "views/sections/SectionExpectation.js";
import LocationList from "components/Lists/LocationList.js";

import Footer from "components/Footers/PageFooter.js";

/*
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import SectionExpectation from "views/index-sections/SectionExpectation.js";
import SectionWelcome from "views/index-sections/SectionWelcome.js";
import LocationList from "components/LocationList.js";
import EarthIcon from '@material-ui/icons/Public';
*/

class LocationPage extends Component {

	 render() {
	 	return (
	    	<>
	      		<IndexNavbar />
	      		<div class="list-panel">
	      		<LocationList/>
	      		</div>
	      		<Footer/>
	    	</>
	  	)
	}

	state = {
	    list: []
	};
	
	componentDidMount() {
        fetch('http://jsonplaceholder.typicode.com/users')
            .then(res => res.json())
            .then((data) => {
                this.setState({ list: data })
            })
            .catch(console.log)
    }
}

export default LocationPage;

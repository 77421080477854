/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Component} from 'react';

import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";

// core components

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import PageFooter from "components/Footers/PageFooter.js";

import SectionChurch from "views/sections/SectionChurch.js";
import SectionWorship from "views/sections/SectionWorship.js";
import SectionChristian from "views/sections/SectionChristian.js";


// import 'jquery';

/*
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import SectionChurch from "views/index-sections/SectionChurch.js";
import SectionWorship from "views/index-sections/SectionWorship.js";
import Footer from "components/Footers/DemoFooter.js";
import MessengerCustomerChat from 'react-messenger-customer-chat';
*/


export default class Login extends Component {
	constructor(props) {
		super (props);

		this.state = {
			email :"",
			password : "",
			loginErrora : "",
		};
	}

    render() {
        return (
           <div class="login-container">
           	<login-text>เพื่อดำเนินการต่อโปรดเข้าสู่ระบบหรือลงทะเบียน</login-text>

           	<Button className="login-button facebook"><i class="fa fa-facebook facebook-span"></i>เข้าสู่ระบบด้วย Facebook</Button>
           	<Button className="login-button google"><i class="fa fa-google facebook-span"></i>เข้าสู่ระบบด้วย Google</Button>

           	<div class="line-behind-text"><p>หรือ</p></div>

            <form>
       		  <InputGroup>
                <Input placeholder="Email Address" type="text" />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i aria-hidden={true} className="fa fa-envelope-o" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>

                <a href="#" target="_blank">ลืมรหัสผ่าน</a>

             <Button className="login-button google">ดำเนินการต่อ</Button>

            </form>
            </div>
        );
    }
}
/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function IndexHeader() {

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/img/bg.jpg") + ")"
        }}
        className="page-header"
      >
       <div className="filter" />
        <Container>
          <div className="motto text-center">
            <h1>ยินดีต้อนรับ</h1>
            <h4>ไม่ว่าคุณจะเป็นใคร อยู่ที่ไหน อาชีพอะไร <br></br> ชีวิตคุณมีความหมายและเราอยากช่วยคุณ <br></br> ให้ค้นพบความหมายของชีวิตตามแบบที่พระเจ้าสร้างมา</h4>
            <br />
            <Button
              href="/welcome"
              className="btn-round mr-1"
              color="neutral"
              target="_self"
              outline
            >
              เรียนรู้เพิ่มเติม
            </Button>

          </div>
        </Container>
      </div>

    </>
  );
}

export default IndexHeader;

/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ChurchIcon from '@material-ui/icons/HomeRounded';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';

import {
  Button,
  Modal,
  Input,
  Container
} from "reactstrap";

import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EarthIcon from '@material-ui/icons/Public';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';



const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
  },
  inline: {
    display: 'inline',
  },
}));

// set Style For Panel  (รูปแบบ)
const ExpansionPanel = withStyles({
  root: {
    margin: 'auto',
    maxWidth: '600px',
    maxHeight: '600px',
    padding: '0px',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

// set Style For Panel Summary (ส่วนหัว)
const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },

  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: '0px 8px 0px 8px',

  },
}))(MuiExpansionPanelDetails);


var churchName = "";
var pastorName = "";
var address = "";
var service = "";
var contact = "";
var map = "";
var pastor = "";
var profile = "";

function LocationList () {

  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();
  const [points, setPoints] = React.useState([]);
  

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [modal, setModal] = React.useState(false);
  const toggleModal = (detail) => {
    setModal(!modal);
    if(!modal){
      churchName = detail.name;
      pastor = detail.pastor;
      address = detail.address;
      service = detail.service;
      contact = detail.contact;
      map = detail.map;
      profile = require("assets/img/profile/"+detail.profile);
    }
  };


function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const ListItems = [
  {
    id: '1',
    province: 'กรุงเทพมหานคร',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์กรุงเทพ' ,
        pastor: 'ศาสนาจารย์ทนนท์ ชาญชิตโสภณ ศิษยาภิบาล',
        address: 'อาคาร KPN ชั้น 7 ถนน พระราม 9 แขวง บางกะปิ เขตห้วยขวาง กรุงเทพฯ 10310' , 
        service: 'นมัสการทุกวันอาทิตย์ 10.00 - 12.00 น.' ,
        contact: '02 120 4529',
        map: 'https://goo.gl/maps/Kjgngcn2MQPgFcqx6',
        banner: '',
        profile: 'nxbkk.jpg'
      },
      { name: 'คริสตจักรสานสัมพันธ์ธนบุรี' ,
        pastor: 'ศาสนาจารย์ศรัณย์ ลีฬหเกรียงไกร',
        address: '197 อาคารเน็กซัสธนบุรี ถนนพุทธมณฑลสาย 1 ซอย 9 (ถนนราชมนตรี) แขวงบางด้วน เขตภาษีเจริญ กรุงเทพฯ 10160' , 
        service: 'นมัสการทุกวันอาทิตย์ 10.00 - 12.00 น.' ,
        contact: '02 100 5151',
        map: 'https://goo.gl/maps/DJzP5hjYwbeJpnbb9',
        banner: '',
        profile: 'nxtbr.jpg'
      },
      { name: 'คริสตจักรสานสัมพันธ์พระราม 2' ,
        pastor: 'ศาสนาจารย์กริช คีรีวัลย์',
        address: 'ตึก พระราม 2 เซ็นเตอร์ ชั้น2 แขวง แสมดำ เขตบางขุนเทียน กรุงเทพฯ 10150' ,
        service: 'นมัสการทุกวันอาทิตย์ 15.00 - 17.15 น.' ,
        contact: '095 260 4560',
        map: 'https://maps.app.goo.gl/kLznbZtAQS6W8cx87',
        banner: '',
        profile: 'nxrama2.jpg' 
      }
    ]
  },
  {
    id: '2',
    province: 'ขอนแก่น',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์ขอนแก่น' ,
        pastor: 'อ.พลานุภาพ บุพศิริ',
        address: '999/76 หมู่ 2 ม.ราชพฤกษ์กรีนวิว ถนนเลี่ยงเมือง ต.บ้านเป็ด อ.เมือง จ.ขอนแก่น 40000' , 
        service: 'นมัสการทุกวันอาทิตย์ 10.00 - 12.30 น.' ,
        contact: '099 449 4895',
        map: '',
        banner: '',
        profile: 'nxkonkean.jpg'
      }
    ]
  },
  {
    id: '3',
    province: 'ฉะเชิงเทรา',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์ฉะเชิงเทรา' ,
        pastor: 'อาจารย์สมบัติ ศรีเจริญ',
        address: '178-180 ตลาดขนส่งใหม่ ถ.ฉะเชิงเทรา-บางประกง ต.หน้าเมือง อ.เมือง จ.ฉะเชิงเทรา 24000' , 
        service: 'นมัสการทุกวันอาทิตย์ 10.30 - 12.30 น.' ,
        contact: '083 880 4108',
        map: 'https://goo.gl/maps/GHvjyYUu6uH9tnK88',
        banner: '',
        profile: 'nxchacheonsao.jpg'
      }
    ]
  },
  {
    id: '4',
    province: 'ชลบุรี',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์ชลบุรี' ,
        pastor: 'อาจารย์องค์การ เดชะยา',
        address: '100/13 ม.3 ต.เสม็ด อ.เมืองชลบุรี จ.ชลบุรี 20000' , 
        service: 'นมัสการทุกวันอาทิตย์ 10.30 - 12.00 น.' ,
        contact: '094 397 2111',
        map: 'https://goo.gl/maps/wxFCwpxqBNKBa6s8A',
        banner: '',
        profile: 'nxchonburi.jpg'
      }
    ]
  },
  ,
  {
    id: '5',
    province: 'เชียงใหม่',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์เชียงใหม่' ,
        pastor: 'อาจารย์ศราวุธ ส่งเสริมภักดี',
        address: '33/1 ถนนทุ่งโฮเต็ล ตำบลวัดเกต อำเภอเมือง จังหวัดเชียงใหม่ 50000' , 
        service: 'นมัสการทุกวันอาทิตย์ 10.00 - 12.00 น.' ,
        contact: '086 407 0023',
        map: 'https://goo.gl/maps/xWJaquKKqe4kdviH8',
        banner: '',
        profile: 'nxchiangmai.jpg'
      }
    ]
  },
  {
    id: '6',
    province: 'ชัยนาท',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์ชัยนาท' ,
        pastor: 'อาจารย์กชพร เกษรจันทร์',
        address: '229/5 ม.8 อ.สรรคบุรี จ.ชัยนาท 17140' , 
        service: 'นมัสการทุกวันอาทิตย์ 11.00 - 13.00 น.' ,
        contact: '094 757 2444',
        map: '',
        banner: '',
        profile: 'chainat.jpg'
      }
    ]
  },
  {
    id: '7',
    province: 'นครปฐม',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์ศาลายา' ,
        pastor: 'อาจารย์เกียรติศักดิ์ สุนทราวิรัตน์',
        address: '95 หมู่ 3 ถนน ศาลายา-นครชัยศรี ต.ศาลายา อ.พุทธมณฑล จ.นครปฐม 73170' , 
        service: 'นมัสการทุกวันอาทิตย์ 15.00 - 17.00 น.' ,
        contact: '086 887 4739',
        map: 'https://goo.gl/maps/mjAajDGcXq7i21s58',
        banner: '',
        profile: 'nxsalaya.jpg'
      }
    ]
  },
  {
    id: '8',
    province: 'นครนายก',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์นครนายก' ,
        pastor: 'อาจารย์ธนศักดิ์ โฆตะวาณิชย์',
        address: 'ข/4 351/17 ชลประสิทธิ์ ต.บ้านใหญ่ อ.เมือง นครนายก 26000' , 
        service: 'นมัสการทุกวันอาทิตย์ 14.00 - 17.00 น.' ,
        contact: '081 812 5165',
        map: 'https://goo.gl/maps/faP8JfncvvwBisuj7',
        banner: '',
        profile: 'nxnakornnayok.jpg'
      }
    ]
  },
  {
    id: '9',
    province: 'นครราชสีมา',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์นครราชสีมา' ,
        pastor: 'อาจารย์พลานุภาพ บุพศิริ',
        address: '234  สมาคมธรรมศาสตร์ ถ.จอมสุรางค์ยาตร์  ต.ในเมือง อ.เมือง จ.นครราชสีมา 30000' , 
        service: 'นมัสการทุกวันอาทิตย์ 10.00 - 12.30 น.' ,
        contact: '097 097 5704',
        map: 'https://goo.gl/maps/wLcqqFK7k4cAj9E1A',
        banner: '',
        profile: 'nxkorat.jpg'
      },
      { name: 'คริสตจักรสานสัมพันธ์นครราชสีมา2' ,
        pastor: 'อาจารย์มานพ บรรณจิตร',
        address: '806/14 ม 4 ต.หัวทะเล อ.เมือง จ.นครราชสีมา 30000 ' , 
        service: 'นมัสการทุกวันอาทิตย์ 10.00 - 12.00 น.' ,
        contact: '080 727 8008',
        map: '',
        banner: '',
        profile: 'nxkorat2.jpg'
      },
      { name: 'คริสตจักรสานสัมพันธ์บ้านเหลื่อม' ,
        pastor: 'อาจารย์ยงยุทธิ์ หาริกัน',
        address: '179 ม.1 ต.บ้านเหลื่อม อ.บ้านเหลื่อม จ.นครราชสีมา 30350' , 
        service: 'นมัสการทุกวันอาทิตย์ 10.00 - 12.00 น.' ,
        contact: '088 377 6975',
        map: '',
        banner: '',
        profile: 'nxkorat3.jpg'
      }
    ]
  },
  {
    id: '11',
    province: 'ปราจีนบุรี',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์กบินทร์บุรี' ,
        pastor: 'อาจารย์พสิษฐ์ จำปาแสน',
        address: 'บริษัท ภูษิตา เซอร์วิสชิ่ง จำกัด เลขที่ 379/611 หมู่ที่ 10 ต.หนองกี่ อ.กบินทร์บุรี จ.ปราจีนบุรี 25110' , 
        service: 'นมัสการทุกวันอาทิตย์' ,
        contact: '-',
        map: '',
        banner: '',
        profile: 'ch.png'
      }
    ]
  },

  {
    id: '12',
    province: 'เพชรบูรณ์',
    church: [
      { name: 'คริสตจักรคันประทีปเพชรบูรณ์' ,
        pastor: 'อาจารย์สุดสาคร กาพย์แก้ว',
        address: '3/3 ซอยไทรงามพัฒนา ต.ในเมือง อ.เมือง จ.เพชรบูรณ์ 67000' , 
        service: 'นมัสการทุกวันอาทิตย์ 09.00 - 12.00 น.' ,
        contact: '089 640 1199',
        map: 'https://goo.gl/maps/6j95djU4Zj5BLTrZ8',
        banner: '',
        profile: 'nxpetch1.jpg'
      },
      { name: 'คริสตจักรคันประทีปวังโป่ง' ,
        pastor: 'อาจารย์วิไลวรรณ ยอดรัก',
        address: '404 ม.14 ต.วังหิน อ.วังโป่ง จ.เพชรบูรณ์ 67240' , 
        service: 'นมัสการทุกวันอาทิตย์ 09.00 - 12.00 น.' ,
        contact: '089 640 1199',
        map: '',
        banner: '',
        profile: 'nxpetch2.jpg'
      },
      { name: 'คริสตจักรคันประทีปประชาอุทิศ' ,
        pastor: 'อาจารย์ทุ่งสง  สิงห์เส',
        address: '366/5 ถ.ประชาอุทิศ ต.วัดป่า อ.หล่มสัก จ.เพชรบูรณ์ 67110' , 
        service: 'นมัสการทุกวันอาทิตย์ 09.00 - 12.00 น.' ,
        contact: '085 877 0183',
        map: '',
        banner: '',
        profile: 'nxpetch3.jpg'
      },
      { name: 'คริสตจักรคันประทีปหล่มเก่า' ,
        pastor: 'อาจารย์วรพล ชัยบูรณ์',
        address: '24 ม.4 ต.หล่มเก่า อ.หล่มเก่า จ.เพชรบูรณ์' , 
        service: 'นมัสการทุกวันอาทิตย์ 09.00 - 12.30 น.' ,
        contact: '081 601 4913',
        map: '',
        banner: '',
        profile: 'ch.png'
      },
      { name: 'คริสตจักรสานสัมพันธ์หล่มสัก' ,
        pastor: 'อาจารย์พัชญานีย์ วงศ์ใหญ่',
        address: '100/1 หมู่ 1 ต.บ้านหวาย อ.หล่มสัก จ.เพชรบูรณ์ 67110' , 
        service: 'นมัสการทุกวันอาทิตย์ 09.00 - 12.30 น.' ,
        contact: '081 040 0585',
        map: '',
        banner: '',
        profile: 'nxpetch5.jpg'
      },
      { name: 'คริสตจักรสานสัมพันธ์เมืองเพชรบูรณ์' ,
        pastor: 'อาจารย์แสงสุรีย์ วังโฉม',
        address: '52/50 ถ.เทพาพัฒนา ต.ในเมือง อ.เมือง เทศบาลเมืองเพชรบูรณ์ 67000' , 
        service: 'นมัสการทุกวันอาทิตย์ 09.00 - 12.30 น.' ,
        contact: '089 958 2000',
        map: '',
        banner: '',
        profile: 'ch.png'
      },
      { name: 'คริสตจักรสานสัมพันธ์วิเชียรบุรี' ,
        pastor: 'อาจารย์ลีนา โสภาพร',
        address: '109 ม.10 ต.ท่าโรง อ.วิเชียรบุรี  จ.เพชรบูณ์ 67130' , 
        service: 'นมัสการทุกวันอาทิตย์ 09.00 - 12.00 น.' ,
        contact: '088 281 3340',
        map: '',
        banner: '',
        profile: 'ch.png'
      },
      { name: 'คริสตจักรสานสัมพันธ์บึงสามพัน' ,
        pastor: 'อาจารย์วินัย โสภาพร',
        address: 'หมู่ 3 ซอย เทศบาล 13 ต.ซับสมอทอด อ.บึงสามพัน จ.เพชรบูรณ์ 67160' , 
        service: 'นมัสการทุกวันอาทิตย์ 09.00 - 12.30 น.' ,
        contact: '080 513 2258',
        map: '',
        banner: '',
        profile: 'nxpetch8.jpg'
      },
      { name: 'คริสตจักรสานสัมพันธ์เขาค้อ' ,
        pastor: 'อาจารย์นงลักษณ์ เผ่าสา',
        address: '84/3 หมู่ 11 ต.ทุ่งสมอ อ.เขาค้อ จ.เพชรบูรณ์ 67110' , 
        service: 'นมัสการทุกวันอาทิตย์ 09.00 - 12.30 น.' ,
        contact: '080 687 3213',
        map: '',
        banner: '',
        profile: 'nxpetch9.jpg'
      }
    ]
  },
  {
    id: '13',
    province: 'ราชบุรี',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์ราชบุรี' ,
        pastor: 'อาจารย์สุรใจ แสงอากาศ',
        address: '42 ม.3 ต.บัวงาม อ.ดำเนินสะดวก จ.ราชบุรี 70210' , 
        service: 'นมัสการทุกวันอาทิตย์ 10.00 - 12.00 น.' ,
        contact: '099 084 3922',
        map: '',
        banner: '',
        profile: 'nxratchaburi1.jpg'
      },
      { name: 'คริสตจักรเมืองราชบุรี' ,
        pastor: 'อาจารย์มารุต รุจน์รัชตะ',
        address: 'เลขที่ 433/170 ถ.ศรีสุริยวงศ์ ต.หน้าเมือง อ.เมืองราชบุรี จ.ราชบุรี 70000' , 
        service: 'นมัสการทุกวันอาทิตย์ 09.00 - 12.00 น.' ,
        contact: '080 024 7070',
        map: '',
        banner: '',
        profile: 'nxratchaburi2.jpg'
      }
    ]
  },
  {
    id: '14',
    province: 'ลพบุรี',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์ชัยบาดาล' ,
        pastor: 'อาจารย์กัญญา สิลาสลุง',
        address: '57/1 หมู่2 ต.ท่าดินดำ อ.ชัยบาดาล จ.ลพบุรี 15130' , 
        service: 'นมัสการทุกวันอาทิตย์ 09.00 - 12.00 น.' ,
        contact: '064 079 7952',
        map: '',
        banner: '',
        profile: 'ch.png'
      }
    ]
  },
  {
    id: '15',
    province: 'เลย',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์เชียงคาน' ,
        pastor: 'อาจารย์อมรรัตน์ พูลเกิด',
        address: '175/5. ซ7บน ม.1 ต.เชียงคาน อ.เชียงคาน จ.เลย 42110' , 
        service: 'นมัสการทุกวันอาทิตย์ 10.30 - 12.30 น.' ,
        contact: '088 556 2292',
        map: '',
        banner: '',
        profile: 'nxloey1.jpg'
      },
      { name: 'คริสตจักรสานสัมพันธ์เมืองเลย' ,
        pastor: 'อาจารย์เรนเน เด ลา โตเร เบซันเด',
        address: '79/24 บ้านนาหนอง ถ.เลย-ด่านซ้าย ต.กุดป่อง อำเภอเมือง จังหวัดเลย 42000' , 
        service: 'นมัสการทุกวันอาทิตย์ 10.30 - 12.30 น.' ,
        contact: '098 586 0821',
        map: '',
        banner: '',
        profile: 'nxloey2.jpg'
      }
    ]
  },
  {
    id: '16',
    province: 'อุทัยธานี',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์อุทัยธานี' ,
        pastor: 'อาจารย์บังอร โพธิ์น้อย',
        address: '192/5 ถ.รักการดี ต.อุทัยใหม่ อ.เมือง จ.อุทัยธานี 61000' , 
        service: 'นมัสการทุกวันอาทิตย์ 09.00 - 12.00 น.' ,
        contact: '090 464 9668',
        map: '',
        banner: '',
        profile: 'nxuthai.jpg'
      }
    ]
  },
  {
    id: '17',
    province: 'ต่างประเทศ',
    church: [
      { name: 'คริสตจักรสานสัมพันธ์ซาราโซต้า' ,
        pastor: 'อาจารย์นิยม ชาญศิริเมธา',
        address: '7646 lockwood ridge Rd. Sarasota, Florida 34243' , 
        service: 'นมัสการทุกวันอาทิตย์ 11.00 - 12.30 น.' ,
        contact: '941 524 9920',
        map: '',
        banner: '',
        profile: 'nxsarasota.jpg'
      }
    ]
  }
];


  return(
    <div>
      <ExpansionPanel>
          <ExpansionPanelSummary>
              <list-header>จังหวัดที่มีโบสถ์ของ NEXUS</list-header>
          </ExpansionPanelSummary>
      </ExpansionPanel> 


      {ListItems.map(item => 
        <ExpansionPanel square expanded={expanded === item.id} onChange={handleChange(item.id)}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <list-header>{item.province}</list-header>
          </ExpansionPanelSummary>
      
      <ExpansionPanelDetails>
          <List className={classes.root}>
            {item.church.map(detail => 
              <ListItemLink onClick={() => toggleModal(detail)} alignItems="flex-start">
              <ListItemAvatar><ChurchIcon /></ListItemAvatar>

                  <div class="MuiListItemText-root MuiListItemText-multiline">
                  <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock">{detail.name}</span>
                  <p class="MuiTypography-root MuiListItemText-secondary list-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">{detail.address}</p>
                  </div>
              </ListItemLink>
            )}
          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>        
      )}

      <ExpansionPanel square expanded='true'>
      <ExpansionPanelDetails>
          <List className={classes.root}>
          <div class="text-center">
          <span class="MuiTypography-root MuiListItemText-primary list-body1 MuiTypography-displayBlock"><br/>ไม่มีโบสถ์ NEXUS ในจังหวัดของคุณ<br/>คุณสามารถติดตามถ่ายทอดสดของเราได้ทาง Youtube Channel<br/><br/></span>
          
          <Button
                className="btn-round  ml-2"
                href="https://www.youtube.com/user/nexusfellowship"
                target="_blank"
                color="info"

              >
                <EarthIcon /> ดู Channel ของเรา
              </Button>
          </div> 
          </List>
      </ExpansionPanelDetails>
      </ExpansionPanel>


      <Modal isOpen={modal} toggle={toggleModal}>
          <div className="modal-body text-center">
              <div
                style={{
                    backgroundImage: "url(" + require("assets/img/ch/bkk.jpg") + ")"
                }}
                className="modal-banner"
                data-parallax={true}
              >
              </div>

              <div className="profile-avatar">
                <img
                  alt="..."
                  className="img-profile img-no-padding img-responsive"
                  src={profile}


                />
              </div>
                <modal-title>{churchName}</modal-title>
                <modal-sub-title>{pastor}</modal-sub-title>
                <modal-text>{address}</modal-text>
                <modal-text>{service}</modal-text>
                <modal-text>ติดต่อโทร <a>{contact}</a></modal-text>

          </div>
                <div className="modal-footer">
                  <div className="left-side">
                    <Button
                    className="btn-round  ml-2"
                    href={map}
                    target="_blank"
                    color="info"

                    >
                <DirectionsCarIcon /> ดูเส้นทาง
              </Button>
                  </div>
                  <div className="divider" />
                  <div className="right-side">
                    <Button className="btn-link" color="danger" type="button" onClick={toggleModal}>
                      ปิด
                    </Button>
                  </div>
                </div>
      </Modal>
    </div>
  );    
}



export default LocationList;
/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactPlayer from "react-player"
// plugin that creates slider

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function SectionGodisReal() {

  return (
    <>
    <Container>
    <div className="motto text-center">
         <h2>พระเจ้ามีจริงไหม</h2><br></br>
    </div>
          <div className='player-wrapper'>
            <ReactPlayer 
              controls="true"
              youtubeConfig={{ playerVars: { showinfo: 1 } }}
              url="https://youtu.be/3gmhxg_AfKE"
              className='react-player'
              width='100%'
              height='100%'
            />
          </div>
    <div className="text-left">
         <h2>ความมีระเบียบ ≠ ความบังเอิญ</h2> 
         <h4>โลกเราถูกสร้างขึ้นอย่างมีระบบระเบียบ ต้นไม้ที่ดูดซับแก๊สคาร์บอนไดออกไซด์และปล่อยแก๊สออกซิเจนในเวลากลางวัน การเกิดน้ำขึ้นน้ำลงเพราะแรงดึงดูดจากดวงจันทร์ รวมไปถึงระบบต่างๆ ในร่างกายของมนุษย์ที่มีความซับซ้อน เช่น ระบบหมุนเวียนเลือด ระบบขับถ่าย ระบบประสาท เป็นต้น หากระบบต่างๆ เหล่านี้ทำงานผิดเพี้ยนไปโลกของเราก็คงไม่สามารถดำรงอยู่ได้ </h4><br/>
         <h4>หากโลกเกิดจากการระเบิดครั้งใหญ่ การระเบิดนั้นสร้างสิ่งที่เป็นระเบียบเหล่านี้ได้หรือ ต้องเกิดความบังเอิญมากเท่าไรจึงจะเกิดระบบที่สมบูรณ์และพอดีขนาดนี้ สิ่งต่างๆ ล้วนราวกับว่าถูกออกแบบและสร้างโดยใครบ้างคน ผู้ที่ออกแบบและสร้างสิ่งต่างๆ เหล่านี้เองที่คริสเตียนเรียกว่าพระเจ้า</h4>
    </div>
    <Row>
    </Row>
    </Container>
    </>
  );
}

export default SectionGodisReal;
/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactPlayer from "react-player"
// plugin that creates slider

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function SectionTestimony() {

  return (
    <>
    <Container>
    <div className="motto text-center">
         <h2>พระเจ้าเปลี่ยนแปลงฉันอย่างไร</h2><br></br>
    </div>
          <div className='player-wrapper'>
            <ReactPlayer 
              controls="true"
              youtubeConfig={{ playerVars: { showinfo: 1 } }}
              url="https://youtu.be/fcudCYXWYzc"
              className='react-player'
              width='100%'
              height='100%'
            />
          </div>
    <div className="text-left">
         <h2>ชีวิตที่ถูกเปลี่ยนเมื่อรู้จักพระเจ้า</h2> 
         <h4>เมื่อเรากลับมาหาพระเจ้า พระองค์จะทรงรื้อฟื้นชีวิตของเราให้กลับสู่มาตรฐานที่พระองค์ทรงตั้งไว้ มีผู้คนมากมายที่ชีวิตเปลี่ยนแปลงไปเมื่อเขาได้รู้จักกับพระเจ้า คุณสามารถรับชมเรื่องราวของพวกเขาได้<a href="https://www.youtube.com/watch?v=ojz89CDhmfU&list=PLGNd7wHAZutLMF1apa0r8cwUVMOaAFOjL" target="_blank">ที่นี่</a></h4><br/>
      </div>
    <Row>
    </Row>
    </Container>
    </>
  );
}

export default SectionTestimony;

/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactPlayer from "react-player"

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// plugin that creates slider

// reactstrap components
import {
  Button,
  Modal,
  FormGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button2 from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles({
  root: {
    maxWidth: 0,
    minWidth: 250,
    height: 275,
    margin: 'auto',
    textAlign: 'center',

  },

  media: {
    maxWidth: 150,
    height: 150,
    backgroundPosition: 'top',
    margin: 'auto',
  },
});

function SectionMission() {

  
  const classes = useStyles();

  return (
    <>
    <div className="section text-center">
        <Container>
      <Row className="justify-content-md-center temp">
            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/core ministry/Mission_01.png")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>สร้างคริสตจักรท้องถิ่นที่เข้มแข็ง</leader-name>
                </CardContent>
              </Card>
            </Col>
            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/core ministry/Mission_02.png")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>ตั้งคริสตจักรใหม่</leader-name>
                </CardContent>
              </Card>
            </Col>

            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/core ministry/Mission_03.png")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>รับใช้ร่วมกับพี่น้องในพระกาย</leader-name>
                </CardContent>
              </Card>
            </Col>

            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/core ministry/Mission_04.png")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>ทำดีช่วยเหลือสังคม</leader-name>
                </CardContent>
              </Card>
            </Col>
          </Row>
          </Container>
      </div>
    </>
  );

}
export default SectionMission;

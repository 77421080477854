/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button2 from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    minWidth: 250,
    margin: 'auto',
    textAlign: 'left',

  },
  media: {
    height: 300,
    backgroundPosition: 'top',
  },
});

function SectionChristian() {

  const classes = useStyles();

  return (
    <>
      <div className="section section-christian text-center">
        <Container>
          <div className="title">
            <h2>เราทำงานร่วมกันเป็นทีม</h2>
          </div>
          <div className="title">
          <h4>เรามุ่งมั่นที่จะประกาศความรักและความรอดจากพระเยซูคริสต์ไปสู่ผู้คนในสังคม เพื่อนำการคืนดีและความสัมพันธ์ระหว่างมนุษย์กับพระเจ้าให้เกิดขึ้นในชีวิตอีกครั้งหนึ่ง</h4>        
          </div>
          <Button className="btn-round mr-1" color="info" href="/Teams">
                  รู้จักทีมผู้นำของเรา
          </Button>


        </Container>
      </div>
    </>
  )
}

export default SectionChristian;

/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function SectionChurch() {

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/img/bg-church.jpg") + ")"
        }}
        className="page-background"
        data-parallax={true}
      >
        <div className="filter" />

        <Container>
          <div className="motto text-right">
            <h1>เรารวมตัวกันที่โบสถ์</h1>
            <h4><b>โบสถ์ไม่ใช่สถานที่แต่คือชุมชน</b> Nexus เรามีโบสถ์ในหลายๆแห่งทั่วประเทศไทย หากคุณอยากพบกับมิตรภาพ ลองมาที่โบสถ์แล้วคุณจะพบกับพี่น้องที่อยากเป็นเพื่อนคุณและพร้อมช่วยเหลือเหมือนคนในครอบครัว</h4>        
            <br />
            <Button className="btn-round" color="neutral" href="/locations" outline >
              เริ่มต้นหาโบสถ์
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SectionChurch;

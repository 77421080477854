/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactPlayer from "react-player"
// plugin that creates slider

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function SectionGodRelate() {

  return (
    <>
    <Container>
    <div className="motto text-center">
         <h2>พระเจ้าเกี่ยวข้องกับเราอย่างไร</h2><br></br>
    </div>
          <div className='player-wrapper'>
            <ReactPlayer 
              controls="true"
              youtubeConfig={{ playerVars: { showinfo: 1 } }}
              url="https://youtu.be/LuQrOja3RuU"
              className='react-player'
              width='100%'
              height='100%'
            />
          </div>
      <div className="text-left">
         <h2>พระเจ้าสร้างมนุษย์จากพระลักษณะของพระองค์ </h2> 
         <h4>พระเจ้าทรงใส่ลักษณะของพระองค์เข้ามาในมนุษย์ ความรัก ความดี ความยุติธรรม ความเมตตา แต่เพราะว่ามนุษย์ไม่เชื่อฟังพระเจ้าและทำบาป ทำให้มนุษย์ตกจากมาตรฐานของพระเจ้า เพราะความบาปนี้เองทำให้มนุษย์ถูกแยกออกจากพระเจ้า และต้องรับโทษของความบาปนั้นซึ่งคือความตาย</h4><br/>
         <h4>พระเจ้าไม่ทรงปรารถนาให้มนุษย์ต้องตาย พระองค์จึงได้ประทานพระบุตรของพระองค์ คือ พระเยซูคริสต์ ลงมาบังเกิดเป็นมนุษย์ พระองค์ทรงตายเพื่อไถ่บาปแก่มนุษย์และในวันที่สามพระองค์ทรงฟื้นขึ้นจากความตายเพื่อเราจะหลุดพ้นจากอำนาจของบาป วันนี้พระเยซูคริสต์ทรงไถ่เราทุกคน แต่หน้าที่ของเราคือยอมรับการไถ่นั้นโดยการเชื่่อและไว้วางใจในพระเยซูคริสต์</h4>
      </div>
    <Row>
    </Row>
    </Container>
    </>
  );
}

export default SectionGodRelate;

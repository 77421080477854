/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function SectionChurch() {

  return (
    <>
      <div className="section section-church text-center">
        <Container>
          <div className="motto text-center">
              <h2>เราช่วยเหลืออะไรคุณได้บ้าง</h2>
          </div>
          
          <div>
              <h4>ที่โบสถ์ Nexus คุณจะได้รับการต้อนรับอย่างอบอุ่นและเป็นกันเองจากคนมากมายที่อยากพบคุณ เราอยากจะช่วยให้ชีวิตของคุณดีขึ้นและมีความสุขมากขึ้น ลองเปิดใจมาพบกับเรา แล้วดูว่าเราจะช่วยให้ชีวิตคุณดีขึ้นได้อย่างไรบ้าง</h4>
              <h2>สิ่งที่คุณจะพบที่รอบนมัสการประจำสัปดาห์</h2>  
          </div>

          
          <div class="col-md-12 offset-md-2">
          <ul>
            <li>การอธิษฐานและแบ่งปันประสบการณ์ในชุมชนคริสเตียน</li>
            <li>เพลงนมัสการที่แตะใจของคุณ</li>
            <li>คำสอนให้แง่คิดในการใช้ชีวิตและให้กำลังใจจากพระคัมภีร์</li>
            <li>กิจกรรมกลุ่มพูดคุยหลังอาหารกลางวันที่ทำให้เรารู้จักกันมากขึ้น</li>     
          </ul>
          </div>
          <div>
              <h2>เปิดใจเริ่มต้นเพื่อคุณจะได้พบกับ</h2>  
          </div>
          
          <div class="col-md-8 offset-md-2">
          <ul>
            <li><strong>ชุมชนที่เหมาะกับคุณ</strong> เรามีกลุ่มที่จะช่วยให้คุณเติบโต กลุ่มที่หัวเราะด้วยกัน และร้องไห้ไปกับคุณ เรามีกลุ่มหลากหลายไม่ว่าจะเป็นกลุ่มครอบครัว คนทำงาน วัยรุ่น เด็ก กลุ่มกิจกรรม กลุ่มกีฬา และกลุ่มอื่นๆอีกมากมาย</li>
            <li><strong>โอกาสที่จะสร้างการเปลี่ยนแปลง</strong> พระเจ้าทรงสร้างคุณให้มีทักษะและศักยภาพ เพื่อที่จะสร้างการเปลี่ยนแปลงในชุมชนของคุณและในโลกนี้ รับใช้ร่วมรับเราและมาสร้างการเปลี่ยนแปลงร่วมกัน</li>
            <li><strong>เส้นทางที่จะเติบโตขึ้นในทุกๆ ด้าน</strong> เราอยากจะพัฒนาชีวิตของคุณเติบโตขึ้น และเปลี่ยนแปลงในทางที่ดีขึ้นในทุกๆ ด้านในชีวิตคุณ</li>   
          </ul>
          </div>
          <Button className="btn-round mr-1" color="info" href="/locations">
                  เริ่มต้นหาโบสถ์
          </Button>
        </Container>
      </div>
    </>
  );
}

export default SectionChurch;

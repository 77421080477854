/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row } from "reactstrap";

// core components

function KnowGodHeader() {

  return (
    <>
     <div
        style={{
          backgroundImage: "url(" + require("assets/img/bg-knowgod.jpg") + ")"
        }}
        className="page-header"
      >
       <div className="filter" />
        <Container>
          <div className="motto text-center">
            <h1>รู้จักพระเจ้า</h1>
            <h4>เราดีใจที่คุณอยากรู้จักเรื่องราวพระเจ้าเพิ่มขึ้น คุณสามารถดูเนื้อที่เราเตรียมไว้สำหรับคุณ หากคุณมีข้อสงสัยหรือต้องการข้อมูลเพิ่มเติม เรายินดีอย่างมากที่จะช่วยคุณ</h4><br/>
            <Button className="btn-round mr-1" color="info" href="https://docs.google.com/forms/d/e/1FAIpQLSeNQs-ezc-MEduOH-Fn0QFTfskwqgffoT6ZTLwgdYNvEwFXoA/viewform">
                  ฉันขอตัดสินใจรับพระเยซูเข้ามาในชีวิต
      </Button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default KnowGodHeader;

/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function SectionChristian() {

  return (
    <>
      <div className="section section-christian text-center">
        <Container>
          <div className="title">
            <h2>เราคือคริสเตียน</h2>
          </div>
          <div className="title">
          <h4><b>NEXUS เราคือชุมชนคริสเตียน</b> คริสเตียนคือคนที่เชื่อ รัก และติดตามคำสอนของพระเยซูคริสต์ เราเชื่อว่าพระเจ้าเป็นผู้สร้างทุกสิ่งรวมถึงชีวิตของคุณด้วย หากคุณสนใจที่จะรู้จักเรื่องราวของพระเจ้าเพิ่มเติม เรายินดีที่ให้มูลเพื่อที่คุณจะได้รู้จักเรื่องราวพระเจ้ามากขึ้น</h4>        
          </div>
          <Button className="btn-round mr-1" color="info" href="/KnowGod">
                  รู้จักพระเจ้า
          </Button>
        </Container>
      </div>
    </>
  );
}

export default SectionChristian;

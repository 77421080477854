/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import KnowGodNavbar from "components/Navbars/KnowGodNavbar.js";

import KnowGodHeader from "components/Headers/KnowGodHeader.js";
import PageFooter from "components/Footers/PageFooter.js";

import SectionWelcome from "views/sections/SectionWelcome.js";
import SectionWorship from "views/sections/SectionWorship.js";
import SectionGodisReal from "views/sections/SectionGodisReal.js";
import SectionGodRelate from "views/sections/SectionGodRelate.js";
import SectionTestimony from "views/sections/SectionTestimony.js";
import MessengerCustomerChat from 'react-messenger-customer-chat';


// import 'jquery';

/*
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import SectionChurch from "views/index-sections/SectionChurch.js";
import SectionWorship from "views/index-sections/SectionWorship.js";
import Footer from "components/Footers/DemoFooter.js";
import MessengerCustomerChat from 'react-messenger-customer-chat';
*/


function KnowGodPage() {
  
 
  return (
    <>
      <IndexNavbar />
      <KnowGodHeader />
      <SectionGodisReal />
      <SectionGodRelate />
      <SectionTestimony />
      <Container className="text-center	">
      <Button className="btn-round mr-1" color="info" href="https://docs.google.com/forms/d/e/1FAIpQLSeNQs-ezc-MEduOH-Fn0QFTfskwqgffoT6ZTLwgdYNvEwFXoA/viewform">
                  ฉันขอตัดสินใจรับพระเยซูเข้ามาในชีวิต
      </Button>
      </Container>
      <PageFooter />
    </>
  );
}

export default KnowGodPage;

/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button2 from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    minWidth: 250,
    margin: 'auto',
    textAlign: 'left',

  },
  media: {
    height: 500,
    backgroundPosition: 'top',
  },
});

function SectionChristian() {

  const classes = useStyles();

  return (
    <>
    <div
        style={{
          backgroundImage: "url(" + require("assets/img/bg-team.jpg") + ")"
        }}
        className="page-header"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <div className="motto text-center">
            <br />
            <h1>เรารับใช้ร่วมกันเป็นทีม</h1>
            <h4>เพราะร่างกายต่างมีหลายอวัยวะ เราจึงต้องการกันและกันเพื่อขับเคลื่อนพันธกิจ</h4>
            
          </div>
        </Container>
      </div>
      <div className="section section-christian text-center">
        <Container>
          <div className="title">
            <h2>ทีมผู้นำของเรา</h2>
          </div>

          <Row className="justify-content-md-center temp">
            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/pastors/thanon.jpg")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>ศาสนาจารย์ทนนท์ ชาญชิตโสภณ</leader-name>
                    <br/>
                    <leader-title>ประธานคณะฯ</leader-title>
                    <list-body2><br/><br/>
                    </list-body2>
                </CardContent>
              </Card>
            </Col>
            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/pastors/saran.jpg")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>ศาสนาจารย์ศรัณย์ ลีฬหเกรียงไกร</leader-name>
                    <br/>
                    <leader-title>รองประธานคณะฯ</leader-title>
                    <list-body2><br/><br/>
                    </list-body2>
                </CardContent>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-md-center temp">
            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/pastors/worachat.jpg")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>ศาสนาจารย์วรชาติ จันทร์ลา</leader-name>
                    <br/>
                    <leader-title>รองประธานคณะฯ</leader-title>
                    <list-body2><br/><br/>
                    </list-body2>
                </CardContent>
              </Card>
            </Col>
            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/pastors/narong.jpg")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>ศาสนาจารย์ณรงค์ ตันละมัยงาม</leader-name>
                    <br/>
                    <leader-title>กรรมการ</leader-title>
                    <list-body2><br/><br/>
                    </list-body2>
                </CardContent>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-md-center temp">
            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/pastors/niyom.jpg")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>อาจารย์นิยม ชาญศิริเมธา</leader-name>
                    <br/>
                    <leader-title>กรรมการ</leader-title>
                    <list-body2><br/><br/>
                    </list-body2>
                </CardContent>
              </Card>
            </Col>
            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/pastors/ongkarn.jpg")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>อาจารย์องค์การ เดชะยา</leader-name>
                    <br/>
                    <leader-title>กรรมการ</leader-title>
                    <list-body2><br/><br/>
                    </list-body2>
                </CardContent>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-md-center temp">
            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/pastors/krich.jpeg")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>ศาสนาจารย์กริช คีรีวัลย์</leader-name>
                    <br/>
                    <leader-title>กรรมการ</leader-title>
                    <list-body2><br/><br/>
                    </list-body2>
                </CardContent>
              </Card>
            </Col>
            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/pastors/benny1.jpeg")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>อาจารย์จักรพันธ์ แกล้วกล้า</leader-name>
                    <br/>
                    <leader-title>กรรมการ</leader-title>
                    <list-body2><br/><br/>
                    </list-body2>
                </CardContent>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-md-center temp">
            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/pastors/palanuparp.jpg")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>อาจารย์พลานุภาพ บุพศิริ​​​ตำ</leader-name>
                    <br/>
                    <leader-title>กรรมการ</leader-title>
                    <list-body2><br/><br/>
                    </list-body2>
                </CardContent>
              </Card>
            </Col>
            <Col className="card-padding">
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={require("assets/img/pastors/thippawan.jpeg")}
                  title="Contemplative Reptile"
                />
                <CardContent>
                    <leader-name>อาจารย์ทิพย์วรรณ แสงอากาศ​​</leader-name>
                    <br/>
                    <leader-title>เหรัญญิก</leader-title>
                    <list-body2><br/><br/>
                    </list-body2>
                </CardContent>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </>
  )
}

export default SectionChristian;

/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/custom-style.scss";

// pages
import Index from "views/pages/IndexPage.js";
import Welcome from "views/pages/WelcomePage.js";
import Location from "views/pages/LocationPage.js";
import KnowGod from "views/pages/KnowGodPage.js";
import Team from "views/pages/Team.js";
import Mission from "views/pages/MissionPage.js";
import Login from "views/pages/LoginPage.js";

import AboutNexus from "views/pages/AboutNexus.js";
import Beliefs from "views/pages/BeliefsPage.js";
import GA from 'components/utils/GoogleAnalytics';

window.$nxbkk = "คริสตจักรสานสัมพันธ์กรุงเทพ";
window.$nxbkk_detail = "24/137 ซ.ลาดพร้าว 21 ถ.ลาดพร้าว จอมพล จตุจักร";

// others

ReactDOM.render(
  <BrowserRouter>
    { GA.init() && <GA.RouteTracker /> }
    <Switch>
      <Route path="/Index" component={Index} />
      <Route path="/Welcome" render={props => <Welcome {...props} />} />
      <Route path="/Locations" render={props => <Location {...props} />} />
      <Route path="/KnowGod" render={props => <KnowGod {...props} />} />
      <Route path="/AboutNexus" render={props => <AboutNexus {...props} />} />
      <Route path="/Beliefs" render={props => <Beliefs {...props} />} />
      <Route path="/Teams" render={props => <Team {...props} />} />
      <Route path="/Login" render={props => <Login {...props} />} />

      <Redirect to="/index" />
      
    
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import {
  Container
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Login from "components/auth/Login.js";
import Footer from "components/Footers/PageFooter.js";


// import 'jquery';

/*
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import SectionChurch from "views/index-sections/SectionChurch.js";
import SectionWorship from "views/index-sections/SectionWorship.js";
import Footer from "components/Footers/DemoFooter.js";
import MessengerCustomerChat from 'react-messenger-customer-chat';
*/

function LoginPage() {
  
 
  return (
    <>
      <IndexNavbar />
      <div class="login-wrapper">
          <Login />
      </div>
      <Footer/>
    </>
  );
}

export default LoginPage;

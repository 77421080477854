/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";

import SectionTeamDetail from "views/sections/SectionTeamDetail.js";

import Footer from "components/Footers/PageFooter.js";


function Mission() {

  return (
    <>
      <IndexNavbar />
      <SectionTeamDetail />
      
      <Footer/>
    </>
  );
}

export default Mission;

/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function SectionChristian() {

  return (
    <>
      <div className="section section-christian text-center">
        <Container>
          <div className="title">
            <h2>พันธกิจแห่งการเชื่อมต่อ</h2>
          </div>
          <div className="title">
          <h4>เรามุ่งมั่นที่จะประกาศความรักและความรอดจากพระเยซูคริสต์ไปสู่ผู้คนในสังคม เพื่อนำการคืนดีและความสัมพันธ์ระหว่างมนุษย์กับพระเจ้าให้เกิดขึ้นในชีวิตอีกครั้งหนึ่ง</h4>        
          </div>
          <div className="title">
            <h2>พันธกิจแห่งการเสริมสร้าง</h2>
          </div>
          <div className="title">
          <h4>เราเชื่อว่าพระเจ้าทรงมีพระประสงค์ต่อมนุษย์ทุกคนไม่เหมือนกัน เราจำเป็นต้องแสวงหาพระประสงค์ของพระเจ้าในชีวิตของเราแต่ละคนโดยเฉพาะผู้เชื่อ คริสตจักรจึงมีหน้าที่ส่งเสริมให้ผู้เชื่อทุกคนแสวงหานิมิตและการทรงเรียกจากพระเจ้า </h4>        
          </div>
          <div className="title">
            <h2>พันธกิจหลัก 4 ด้านของเรา</h2>
          </div>

        </Container>
      </div>
    </>
  );
}

export default SectionChristian;

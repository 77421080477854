/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function SectionChurch() {

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/img/bg-worship.jpg") + ")"
        }}
        className="page-background"
        data-parallax={true}
      >
        <div className="filter" />

        <Container>
          <div className="motto text-left">
            <h1>ใกล้ชิดกับเรา</h1>
            <h4>เรามีข้อมูลมากมายที่น่าจะเป็นประโยชน์กับคุณผ่านหลากหลายช่องทาง<br />ลองเข้ามาดูเพื่อที่จะได้ใกล้ชิดกับเรามากขึ้น</h4>
            <br />
            <div className="credit">
              <a class="btnsc btn-icon btn-facebook btn-color-white" href="https://www.facebook.com/NexusForJesus/"><i class="fa fa-facebook"></i></a>
              <a class="btnsc btn-icon btn-youtube btn-color-white" href="https://www.youtube.com/user/nexusfellowship"><i class="fa fa-youtube"></i></a>
              <a class="btnsc btn-icon btn-twitter btn-color-white" href="https://twitter.com/nexusforjesus"><i class="fa fa-twitter"></i></a>
              <a class="btnsc btn-icon btn-instagram btn-color-white" href="https://www.instagram.com/nexusforjesus"><i class="fa fa-instagram"></i></a>
          </div>
          </div>
        </Container>
      </div>

      
    </>
  );
}

export default SectionChurch;

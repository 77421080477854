/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function SectionBelief() {

  return (
    <>
      <div className="section section-christian text-center">
        <Container>
          <div className="title">
            <h2>เราเชื่อในคำสอนของพระเยซูคริสต์</h2>
          </div>
          <div className="title">
          <h4>เราเชื่อและปฏิบัติตามสิ่งที่พระเยซูคริสต์ได้สอนไว้ตามที่บันทึกในพระคริสตธรรมคัมภีร์ เราเชื่อว่าพระเยซูคริสต์ทรงเป็นพระเจ้าและมนุษย์ที่ไถ่บาปเราอย่างสมบูรณ์</h4>        
          </div>

          <Button className="btn-round mr-1" color="info" href="/Beliefs">
                  ดูหลักข้อเชื่อของเรา
          </Button>
        </Container>
      </div>
    </>
  );
}

export default SectionBelief;
